import React from 'react';
import logo from "../assets/logo.png";
    
const LegalPage = () => (
  <div className="p-10 flex flex-col items-center">
    <a href = "/" ><img src={logo} alt="TTT Logo" className="absolute left-0 top-0 w-14 h-14 md:w-24 md:h-24 m-4" /></a>
    <div>
        <h1 className="text-4xl font-amerigo font-bold mb-6">Privacy Policy & Terms of Service for TalkToTori</h1>
        <p className="mb-4 text-center font-janna">Last updated: May 25, 2023</p>
    </div>
    
    <div className="text-lg space-y-6 flex flex-col xl:w-2/5 items-start">
      <h2 className="font-bold text-3xl font-amerigo">Privacy Policy</h2>

      <h3 className="font-bold font-janna">1. Introduction</h3>
      <p className="font-janna">Welcome to TalkToTori, your AI therapist. We are committed to respecting and protecting your privacy. This Privacy Policy outlines how we handle your personal data. By using our services, you are agreeing to the practices described in this policy.</p>
      
      <h3 className="font-bold font-janna">2. Information We Collect</h3>
      <p className="font-janna">We collect information to provide better services to all our users. This includes basic information such as your IP address and email address, inputs you provide and the responses generated by our site, data that is automatically collected during your interaction with our services, and sensitive and third-party information that you provide. We may also store this information locally on your device.</p>
      
      <h3 className="font-bold font-janna">3. How We Use Your Information</h3>
      <p className="font-janna">The information we collect is used to improve our products and services, for research and development, and to enforce our terms of service and prevent illegal activities. We do not share or sell your personal data to third parties or advertisers.</p>
      
      <h3 className="font-bold font-janna">4. Legal Bases for Processing</h3>
      <p className="font-janna">We process your information based on your consent, our contractual obligations to you, any legal obligations we may have, and our legitimate interests in improving our services.</p>
      
      <h3 className="font-bold font-janna">5. Security Measures</h3>
      <p className="font-janna">We take your privacy seriously and use various security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. It is your responsibility not to violate these measures, engage in illegal activities using our platform, or use it for any unintended purpose.</p>
      
      <h3 className="font-bold font-janna">6. User's Responsibilities</h3>
      <p className="font-janna">TalkToTori is intended to provide guidance rather than factual information. You should not rely solely on the information provided by TalkToTori, but should double-check it and/or consult a professional. Users must be 18 years of age or older to use our services.</p>

      <h2 className="font-bold text-3xl font-amerigo mt-10">Terms of Service</h2>

      <h3 className="font-bold font-janna">1. Acceptance of Terms</h3>
      <p className="font-janna">By accessing and using TalkToTori, you accept and agree to be bound by the terms and provision of this agreement. Additionally, when using our services, you shall be subject to any posted guidelines or rules applicable to such services. If you do not agree to abide by the above, please do not use this service.</p>

      <h3 className="font-bold font-janna">2. User Conduct and Obligations</h3>
      <p className="font-janna">You agree to abide by all applicable local, state, national, and international laws and regulations in your use of the website. Our service is not to be used for any illegal purposes, and you agree to comply with the terms of these Terms of Service.</p>

      <h3 className="font-bold font-janna">3. Description of Services</h3>
      <p className="font-janna">TalkToTori provides an AI-powered therapy platform designed to support emotional health and wellness. We reserve the right to modify or discontinue, temporarily or permanently, the services (or any part thereof) with or without notice. Our services are not intended to be a substitute for professional psychological, psychiatric, or medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</p>

      <h3 className="font-bold font-janna">4. Privacy Policy</h3>
      <p className="font-janna">Your use of TalkToTori signifies acknowledgment of and agreement to our Privacy Policy. You further acknowledge and agree that TalkToTori may use your personal data as described in our Privacy Policy.</p>

      <h3 className="font-bold font-janna">5. Disclaimer of Warranties</h3>
      <p className="font-janna">You expressly understand and agree that your use of TalkToTori is at your sole risk. The service is provided on an "as is" and "as available" basis. TalkToTori expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. TalkToTori makes no warranty that the service will meet your requirements, the service will be uninterrupted, timely, secure, or error-free, the results that may be obtained from the use of the service will be accurate or reliable, or that any errors in the software will be corrected.</p>

      <h3 className="font-bold font-janna">6. Limitation of Liability</h3>
      <p className="font-janna">You expressly understand and agree that TalkToTori shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if TalkToTori has been advised of the possibility of such damages), resulting from your use of the service or the inability to use the service, unauthorized access to or alteration of your transmissions or data, statements or conduct of any third party on the service, or any other matter relating to the service.</p>

      <h3 className="font-bold font-janna">7. Account Termination</h3>
      <p className="font-janna">If you wish to terminate your account, you may do so by contacting aliaadil2002@gmail.com. Any suspension or termination of your account shall not affect your obligations under these Terms of Service (including but not limited to ownership, indemnification, any representations and warranties made by you, and limitation of liability), which by their sense and context are intended to survive such suspension or termination.</p>
    </div>
  </div>
);

export default LegalPage;
